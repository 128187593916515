:root {
  --primary-rgb: 56, 128, 255;
  --primary: #3880ff;
  --primary-contrast: #ffffff;
  --primary-contrast-rgb: 255, 255, 255;
  --primary-shade: #3171e0;
  --primary-tint: #4c8dff;
  --secondary: #3dc2ff;
  --secondary-rgb: 61, 194, 255;
  --secondary-contrast: #ffffff;
  --secondary-contrast-rgb: 255, 255, 255;
  --secondary-shade: #36abe0;
  --secondary-tint: #50c8ff;
  --tertiary: #5260ff;
  --tertiary-rgb: 82, 96, 255;
  --tertiary-contrast: #ffffff;
  --tertiary-contrast-rgb: 255, 255, 255;
  --tertiary-shade: #4854e0;
  --tertiary-tint: #6370ff;
  --success: #2dd36f;
  --success-rgb: 45, 211, 111;
  --success-contrast: #ffffff;
  --success-contrast-rgb: 255, 255, 255;
  --success-shade: #28ba62;
  --success-tint: #42d77d;
  --success-tint-translucent: rgba(66, 215, 125, 0.4);
  --warning: #ffc409;
  --warning-rgb: 255, 196, 9;
  --warning-contrast: #000000;
  --warning-contrast-lighter: rgba(124, 124, 48, 0.7);
  --warning-shadow: rgba(0, 0, 0, 0.39);
  --warning-contrast-rgb: 0, 0, 0;
  --warning-shade: #e0ac08;
  --warning-tint: #ffca22;
  --danger: #eb445a;
  --danger-rgb: 235, 68, 90;
  --danger-contrast: #ffffff;
  --danger-contrast-rgb: 255, 255, 255;
  --danger-shade: #cf3c4f;
  --danger-tint: #ed576b;
  --dark: #222428;
  --dark-rgb: 34, 36, 40;
  --dark-contrast: #ffffff;
  --dark-contrast-rgb: 255, 255, 255;
  --dark-shade: #1e2023;
  --dark-tint: #383a3e;
  --medium: #92949c;
  --medium-rgb: 146, 148, 156;
  --medium-contrast: #ffffff;
  --medium-contrast-rgb: 255, 255, 255;
  --medium-shade: #808289;
  --medium-tint: #9d9fa6;
  --light: #f4f5f8;
  --light-rgb: 244, 245, 248;
  --light-contrast: #000000;
  --light-contrast-rgb: 0, 0, 0;
  --light-shade: #d7d8da;
  --light-tint: #f5f6f9;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-datepicker-toggle.active,
.mdc-icon-button.active {
  border: 5px solid red;
  color: var(--warning) !important;
}

.mat-mdc-fab.active {
  background-color: var(--warning) !important;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--warning) !important;
  --mdc-switch-selected-handle-color: var(--warning) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--warning) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--warning) !important;
  --mdc-switch-selected-focus-handle-color: var(--warning-shade) !important;
  --mdc-switch-selected-hover-handle-color: var(--warning-shade) !important;
  --mdc-switch-selected-pressed-handle-color: var(--warning-shade) !important;
  --mdc-switch-selected-focus-track-color: var(--warning) !important;
  --mdc-switch-selected-hover-track-color: var(--warning) !important;
  --mdc-switch-selected-pressed-track-color: var(--warning) !important;
  --mdc-switch-selected-track-color: var(--warning) !important;
}

.date-filter-picker button.mat-calendar-body-cell.mat-calendar-body-disabled:hover::after {
  content: "max. 10 Tag";
  width: 80px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  padding: 5px;
  background-color: var(--medium);
  color: var(--medium-contrast);
  z-index: 1000;
  opacity: 0.7;
  border-radius: 5px;
}

.job-details-modal {
  width: 500px;
  max-width: 100vw !important;
  height: auto;
}

.day-view-modal {
  width: 600px;
  max-width: 100vw !important;
  height: auto;
}

.select-year.mat-mdc-form-field {
  margin-bottom: -1.25em !important;
}

@media only screen and (max-width: 600px) {
  .job-details-modal,
  .day-view-modal {
    width: 100vw;
    height: 100vh;
  }
}
.mat-mdc-standard-chip {
  background-color: var(--warning) !important;
  border: 1px solid var(--warning-contrast-lighter);
  box-shadow: 3px 3px 3px -2px var(--warning-shadow);
}

@media only screen and (max-width: 600px) {
  .job-details-modal,
  .day-view-modal {
    width: 100vw;
    height: 100vh;
  }
}
.fc {
  max-width: 1100px;
  margin: 0 auto 10px;
}

.fc-daygrid-body {
  width: 100% !important;
}
.fc-daygrid-body .fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-highlight {
  border: 3px solid var(--danger) !important;
}

.fc-header-toolbar,
.fc-day-header,
.fc-day-grid,
.fc-widget-header,
.fc-head {
  display: none;
}

.fc-time-grid-container {
  height: 100% !important;
}

.future.fc-event-future,
.future.fc-event-today {
  cursor: pointer;
}
.future.fc-event-future:hover,
.future.fc-event-today:hover {
  opacity: 0.5;
}

.fc-day.fc-day-future.fc-daygrid-day:not(.fc-day-disabled) {
  cursor: pointer;
}

.past.available.fc-event-past,
.past.unavailable.fc-event-past,
.past.hourly.fc-event-past,
.past.available.fc-event,
.past.unavailable.fc-event,
.past.hourly.fc-event {
  opacity: 0.5;
}

.fc .fc-bg-event .fc-event-title {
  font-style: normal !important;
  margin: 0.2em !important;
}

.fc-daygrid-day-top > a {
  color: var(--dark);
}

.fc-col-header {
  width: 100% !important;
}

.fc-theme-standard td.fc-day {
  border: 1px solid var(--dark) !important;
}

.fc-event {
  box-sizing: border-box !important;
  color: var(--dark) !important;
  font-weight: bold;
}
.fc-event-title, .fc-event-time {
  color: var(--dark);
}
.fc-event.unavailable {
  background-color: var(--danger);
  opacity: 1;
}
.fc-event.available {
  background-color: var(--success);
  opacity: 1;
}
.fc-event.hourly {
  background-color: var(--warning);
  opacity: 1;
}
.fc-event .fc-time {
  display: none;
}
.fc-event .fc-title {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}

@keyframes blink {
  50% {
    color: var(--danger);
  }
}
.blink {
  animation: blink 1s step-start 0s infinite;
}

.blink_me {
  color: var(--danger);
  font-weight: bold;
  animation: blinker 0.3s alternate-reverse infinite;
}

@keyframes blinker {
  100% {
    color: var(--dark);
  }
}