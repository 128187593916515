.fc {
  max-width: 1100px;
  margin: 0 auto 10px;
}

.fc-daygrid-body {
  width: 100% !important;

  .fc-scrollgrid-sync-table {
    width: 100% !important;
  }
}

.fc-highlight {
  border: 3px solid var(--danger) !important;
}

.fc-header-toolbar,
.fc-day-header,
.fc-day-grid,
.fc-widget-header,
.fc-head {
  display: none;
}

.fc-time-grid-container {
  height: 100% !important;
}

.future.fc-event-future,
.future.fc-event-today {
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.fc-day.fc-day-future.fc-daygrid-day:not(.fc-day-disabled) {
  cursor: pointer;
}

.past.available.fc-event-past,
.past.unavailable.fc-event-past,
.past.hourly.fc-event-past,
.past.available.fc-event,
.past.unavailable.fc-event,
.past.hourly.fc-event {
  opacity: 0.5;
}

.fc .fc-bg-event .fc-event-title {
  font-style: normal !important;
  margin: 0.2em !important;
}

.fc-daygrid-day-top > a {
  color: var(--dark);
}

.fc-col-header {
  width: 100% !important;
}

.fc-theme-standard td.fc-day {
  border: 1px solid var(--dark) !important;
}

.fc-event {
  box-sizing: border-box !important;
  color: var(--dark) !important;
  font-weight: bold;

  &-title,
  &-time {
    color: var(--dark);
  }

  &.unavailable {
    background-color: var(--danger);
    opacity: 1;
  }

  &.available {
    background-color: var(--success);
    opacity: 1;
  }

  &.hourly {
    background-color: var(--warning);
    opacity: 1;
  }

  .fc-time {
    display: none;
  }

  .fc-title {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
  }
}
