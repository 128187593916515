:root {
  --primary-rgb: 56, 128, 255;
  --primary: #3880ff;
  --primary-contrast: #ffffff;
  --primary-contrast-rgb: 255, 255, 255;
  --primary-shade: #3171e0;
  --primary-tint: #4c8dff;

  --secondary: #3dc2ff;
  --secondary-rgb: 61, 194, 255;
  --secondary-contrast: #ffffff;
  --secondary-contrast-rgb: 255, 255, 255;
  --secondary-shade: #36abe0;
  --secondary-tint: #50c8ff;

  --tertiary: #5260ff;
  --tertiary-rgb: 82, 96, 255;
  --tertiary-contrast: #ffffff;
  --tertiary-contrast-rgb: 255, 255, 255;
  --tertiary-shade: #4854e0;
  --tertiary-tint: #6370ff;

  --success: #2dd36f;
  --success-rgb: 45, 211, 111;
  --success-contrast: #ffffff;
  --success-contrast-rgb: 255, 255, 255;
  --success-shade: #28ba62;
  --success-tint: #42d77d;
  --success-tint-translucent: rgba(66, 215, 125, 0.4);

  --warning: #ffc409;
  --warning-rgb: 255, 196, 9;
  --warning-contrast: #000000;
  --warning-contrast-lighter: rgba(124, 124, 48, 0.7);
  --warning-shadow: rgba(0, 0, 0, 0.39);
  --warning-contrast-rgb: 0, 0, 0;
  --warning-shade: #e0ac08;
  --warning-tint: #ffca22;

  --danger: #eb445a;
  --danger-rgb: 235, 68, 90;
  --danger-contrast: #ffffff;
  --danger-contrast-rgb: 255, 255, 255;
  --danger-shade: #cf3c4f;
  --danger-tint: #ed576b;

  --dark: #222428;
  --dark-rgb: 34, 36, 40;
  --dark-contrast: #ffffff;
  --dark-contrast-rgb: 255, 255, 255;
  --dark-shade: #1e2023;
  --dark-tint: #383a3e;

  --medium: #92949c;
  --medium-rgb: 146, 148, 156;
  --medium-contrast: #ffffff;
  --medium-contrast-rgb: 255, 255, 255;
  --medium-shade: #808289;
  --medium-tint: #9d9fa6;

  --light: #f4f5f8;
  --light-rgb: 244, 245, 248;
  --light-contrast: #000000;
  --light-contrast-rgb: 0, 0, 0;
  --light-shade: #d7d8da;
  --light-tint: #f5f6f9;
}
