// TODO ed: this needs to be cleaned up

.mat-datepicker-toggle.active,
.mdc-icon-button.active {
  border: 5px solid red;
  color: var(--warning) !important;
}

.mat-mdc-fab.active {
  background-color: var(--warning) !important;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--warning) !important;
  --mdc-switch-selected-handle-color: var(--warning) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--warning) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--warning) !important;
  --mdc-switch-selected-focus-handle-color: var(--warning-shade) !important;
  --mdc-switch-selected-hover-handle-color: var(--warning-shade) !important;
  --mdc-switch-selected-pressed-handle-color: var(--warning-shade) !important;
  --mdc-switch-selected-focus-track-color: var(--warning) !important;
  --mdc-switch-selected-hover-track-color: var(--warning) !important;
  --mdc-switch-selected-pressed-track-color: var(--warning) !important;
  --mdc-switch-selected-track-color: var(--warning) !important;
}

.date-filter-picker {
  button.mat-calendar-body-cell.mat-calendar-body-disabled {
    &:hover {
      &::after {
        content: 'max. 10 Tag';
        width: 80px;
        height: 25px;
        position: absolute;
        font-size: 12px;
        padding: 5px;
        background-color: var(--medium);
        color: var(--medium-contrast);
        z-index: 1000;
        opacity: 0.7;
        border-radius: 5px;
      }
    }
  }
}

.job-details-modal {
  width: 500px;
  max-width: 100vw !important;
  height: auto;
}

.day-view-modal {
  width: 600px;
  max-width: 100vw !important;
  height: auto;
}

.select-year.mat-mdc-form-field {
  margin-bottom: -1.25em !important;
}

@media only screen and (max-width: 600px) {
  .job-details-modal,
  .day-view-modal {
    width: 100vw;
    height: 100vh;
  }
}

.mat-mdc-standard-chip {
  background-color: var(--warning) !important;
  border: 1px solid var(--warning-contrast-lighter);
  box-shadow: 3px 3px 3px -2px var(--warning-shadow);
}

@media only screen and (max-width: 600px) {
  .job-details-modal,
  .day-view-modal {
    width: 100vw;
    height: 100vh;
  }
}
