// TODO ed:  why do we need two of them?
@keyframes blink {
  50% {
    color: var(--danger);
    // opacity: 0.3;
  }
}
.blink {
  animation: blink 1s step-start 0s infinite;
}

.blink_me {
  color: var(--danger);
  font-weight: bold;
  animation: blinker 0.3s alternate-reverse infinite;
}

@keyframes blinker {
  100% {
    color: var(--dark);
  }
}
